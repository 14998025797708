import React from "react";
import { inject, observer } from "mobx-react";
import { isValidReference } from "mobx-state-tree";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import arrayMove from "array-move";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import TimeButtons from "../components/report/timebuttons";
import axios from "axios";
import {
  Dropdown,
  Button,
  Loader,
  Message,
  Header,
  Icon,
  Segment,
  Input,
} from "semantic-ui-react";
import QuestionaryEditModal from "../components/questions/QuestionaryEditModal";
import QuestionaryModal from "../components/questions/QuestionaryModal";
import Codes from "../components/questions/Codes";
import ConfirmButton from "../components/calendar/controls/confirmButton";
import ReportModal from "../components/questions/ReportModal";
import {
  SortableElement,
  sortableHandle,
  SortableContainer,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <Icon
    name="bars"
    size="large"
    style={{ cursor: "row-resize", marginRight: 10 }}
  />
));

const Questionary = SortableElement(({ x, last }) => (
  <Segment
    className="questionarySegment"
    attached={last ? "bottom" : true}
    key={x.id}
  >
    <div className="namecell">
      <DragHandle />
      {x.name}
    </div>
    <div className="buttoncell">
      <QuestionaryModal
        questionaryId={x.id}
        classConstraints={
          new Set(
            Array.from(x._resClasses.values())
              .filter((y) => isValidReference(() => y) && !y.deleted)
              .map((y) => y.id)
          )
        }
        trigger={<Button icon="pencil" />}
      />
      <QuestionaryEditModal
        questionaryId={x.id}
        trigger={<Button icon="settings" />}
      />
      <ConfirmButton
        onClick={(e) => x.delete()}
        question={["questions.delete", { name: x.name }]}
        ok="yes"
        cancel="no"
        trigger={<Button icon="trash alternate" />}
      />
    </div>
  </Segment>
));

const Questionaries = SortableContainer(({ items }) => (
  <div>
    {items.map((x, i, items) => (
      <Questionary key={x.id} x={x} last={i === items.length - 1} index={i} />
    ))}
  </div>
));

class QuestionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      start: moment().startOf("month"),
      end: moment().startOf("day"),
      focusedInput: null,
      loading: true,
      error: false,
      results: [],
      filterValue: "",
      archiveStatus: false,
      foremen_filter:[
        {key: "", value: 0, text: "Select Formen"}
      ],
      foremen_filter_selected: null,
      downloadAction: {
        isLoading: false,
        project: null,
      },
    };
  }

  componentDidMount() {
    /*this.props.appCtx.setEditButtons(
      <Link className="topbutton" to="/">
        <img alt="Back" src={WahlImg} />
      </Link>,
      null
    ); */

    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.start &&
      this.state.end &&
      (!prevState.start ||
        !prevState.end ||
        this.state.start !== prevState.start ||
        this.state.end !== prevState.end)
    ) {
      this.load();
    }
  }

  async load() {
    const start = this.state.start.valueOf();
    const end = this.state.end.endOf("day").valueOf();
    const jwttoken = localStorage.getItem("binfra_token");
    const foremen = this.state.foremen_filter_selected;

    this.setState({ error: false, loading: true });
    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
        "/loadReport/" +
        start +
        "/" +
        end + 
        ( foremen ? '/'+ foremen : '') +
        "?v=" + process.env.REACT_APP_SERVER_VERSION + "&" +
        Math.random(),
        {
          headers: {
            Authorization: "Bearer " + jwttoken,
          },
        }
      );

      let temp_foremen = [];
      this.setState({
        error: false,
        loading: false,
        foremen_filter: [
          {key: "", value: 0, text: "Select Formen"}
        ],
        results: res.data
          .filter((x) => this.props.app.projects.projects.has(x.project))
          .map((x) => {

            // check formen name is in the project
            let answeredBy = '';
            let propResources = this.props.app.resources.resources;
            x.answeredByFormen.map(function(each_formen){
              if(propResources.has(each_formen)){
                answeredBy = propResources.get(each_formen).name;
                temp_foremen[each_formen] = answeredBy;
              }
              return temp_foremen;
            });
            
            return {
              ...x,
              projectName: this.props.app.projects.projects.get(x.project)
                .fullName,
              dateFormatted: moment(x.date).format("DD.MM.YYYY"),
              // answeredBy: x.answeredBy,
              answeredByFormen: x.answeredByFormen,
              // answeredByName: answeredBy,
              hasImages: x.has_images,
              imageList: x.imageList,
            };
          })
          .sort((a, b) =>
            a.date < b.date
              ? -1
              : a.date > b.date
                ? 1
                : a.projectName.localeCompare(b.projectName)
          )
      });

      let new_formen_list = this.state.foremen_filter;
      for (let key in temp_foremen) {
        new_formen_list.push({
          key: key, 
          value: key, 
          text: temp_foremen[key]
        });
      }

      this.setState({
        foremen_filter: new_formen_list,
        foremen_filter_selected: temp_foremen[this.state.foremen_filter_selected] ? this.state.foremen_filter_selected: 0
      });

    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.load.bind(this));
      }
      this.setState({ error: true, loading: false });
      console.log(error);
    }
  }

  componentWillUnmount() {
    //this.props.appCtx.setEditButtons(false);
  }

  foremenFilterChange(value){
    this.setState({ foremen_filter_selected: value });
    setTimeout(() => this.load(), 1000);
  }

  searchByName(event){
    this.setState({ filterValue: event.target.value });
  }


  laodProjectBasedOnArchive(archiveStatus = false){
    this.setState({ archiveStatus: archiveStatus });
  }

  async updateArchiveStatus(event, project, archiveStatus){
    const jwttoken = localStorage.getItem("binfra_token");
    this.setState({ error: false, loading: true });
    let project_date = moment(project.date).utcOffset(0, true).valueOf();
    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
        "/saveArchiveProject/" +
        project.project +
        "/" +
        project_date +
        "/" +
        archiveStatus +
        "?v=" + process.env.REACT_APP_SERVER_VERSION + "&" +
        Math.random(),
        {
          headers: {
            Authorization: "Bearer " + jwttoken,
          },
        }
      );

      if(res.data.status){
        let existing_result = this.state.results;
        this.setState({
          error: false,
          loading: false,
          results: existing_result.map(
            function(value, key){
              if(value.project === project.project && value.date === project.date){
                if(archiveStatus === 'ARCHIVE'){
                  value.archiveStatus = 1;
                }
                else {
                  value.archiveStatus = 0;
                }
              }
              return value;
            }).sort((a, b) =>
            a.date < b.date ? -1 : a.date > b.date ? 1 : a.projectName.localeCompare(b.projectName)
            )
          });
      }


    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.load.bind(this));
      }
      this.setState({ error: true, loading: false });
      console.log(error);
    }
  }

  async downloadImages(event, project) {
    
    let image_list = {};

    try {
      this.setState({'downloadAction': {
        isLoading:true,
        project:project.project + '_' +project.date
      }});
      for (let image in project.imageList) {
        image_list[process.env.REACT_APP_SERVER_URL + "/getFile/" + image + "/x"] = project.projectName + '_' + project.imageList[image]
      }

      let data = {
        'key': 'ac5ccbd0-bd82-4f8f-a8be-3090f7d36ab1',
        'files': JSON.stringify(image_list)
      }

      let qs = require('qs');
      await axios.post(
        "" + process.env.REACT_APP_BPO_URL + "/common/interfaces/Zipper.php",
        qs.stringify(data), { responseType: 'blob' }
      ).then((response) => {
        // create file link in browser's memory
        let href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        let link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', project.projectName+'_'+project.date+'_file.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({'downloadAction': {
          isLoading:false,
          project:null
        }});
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.load.bind(this));
      }
      this.setState({ error: true, loading: false, downloadAction: {
        isLoading:false,
        project:null
      } });
      console.log('question.js->downloadImages', error);
    }
  }
    

  render() {
    const questionaries = this.state.edit
      ? Array.from(this.props.app.questions.questionaries.values())
        .filter((x) => !x.deleted)
        .sort((a, b) =>
          a.ordering < b.ordering
            ? -1
            : b.ordering < a.ordering
              ? 1
              : a.name.localeCompare(b.name)
        )
      : [];
      console.log(this.state.edit);
      console.log(questionaries);

    return (
      <div id="questionPage" className="questionPage groupPage">
        <div id="map_buttons" className="buttonLine">
          <div className="buttonBlock">
            {this.state.edit ? (
              <Button
                onClick={() => this.setState({ edit: false })}
                labelPosition="left"
                content={this.props.t("back")}
                icon="arrow left"
              />
            ) : (
              <div>
                { this.state.archiveStatus ? (
                <Button
                  onClick={(x) => this.laodProjectBasedOnArchive(false)}
                  labelPosition="left"
                  content={this.props.t("back")}
                  icon="arrow left"
                />
                ) :(
                <Button
                  onClick={(x) => this.laodProjectBasedOnArchive(true)}
                  labelPosition="left"
                  content={this.props.t("questions.archive")}
                  icon="archive"
                />
                ) }

                <DateRangePicker
                  startDate={this.state.start ? this.state.start : null} // momentPropTypes.momentObj or null,
                  startDateId="dpicker_start" // PropTypes.string.isRequired,
                  calendarInfoPosition="bottom"
                  numberOfMonths={3}
                  endDate={this.state.end ? this.state.end : null} // momentPropTypes.momentObj or null,
                  endDateId="dpicker_end" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) =>
                    this.setState({
                      start: startDate,
                      end: endDate,
                    })
                  } // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  renderCalendarInfo={(x) => (
                    <TimeButtons
                      onClick={(start, end) =>
                        this.setState({ start: moment(start), end: moment(end) })
                      }
                    />
                  )}
                  startDatePlaceholderText={this.props.t("begin")}
                  endDatePlaceholderText={this.props.t("end")}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  minimumNights={0}
                  withPortal={true}
                />
                <Dropdown
                  button
                  className='icon'
                  floating
                  labeled
                  icon='user'
                  options={this.state.foremen_filter}
                  search
                  placeholder='Select Foremen'
                  style={{marginLeft:'5px'}}
                  lazyLoad={true}
                  onChange={(x, {value})=> this.foremenFilterChange(value)}
                  value ={this.state.foremen_filter_selected}
                />
                <div className="ui input">

                <input 
                    type="text"
                    value={this.state.filterValue}
                    onChange={(e)=>this.searchByName(e)} 
                    name="text"
                    placeholder="search .." 
                  />
                </div>
              </div>
            )}
          </div>
          <div className="buttonBlock">
            {this.state.edit ? (
              <QuestionaryEditModal
                questionaryId={null}
                trigger={
                  <Button
                    labelPosition="left"
                    content={this.props.t("questions.addQuestionary")}
                    icon="add"
                  />
                }
              />
            ) : (
              <React.Fragment>
                <Codes />
                {this.props.app.ui.rights.has("MODIFY_QUESTIONS") ? (
                  <Button
                    onClick={() => this.setState({ edit: true })}
                    labelPosition="left"
                    content={this.props.t("questions.editQuestionaries")}
                    icon="pencil alternate"
                  />
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="groupBody">
          {this.state.edit ? (
            <div className="mockTable">
              <Segment attached="top" className="thead">
                {this.props.t("questions.questionaries.header")}
              </Segment>
              {questionaries.length ? (
                <Questionaries
                  useDragHandle
                  items={questionaries}
                  helperClass="sortableHelper"
                  onSortEnd={({ oldIndex, newIndex }) => {
                    const xq = arrayMove(questionaries, oldIndex, newIndex);
                    for (let i = 0; i < xq.length; i++) {
                      xq[i].setOrdering(i);
                    }
                    return;
                  }}
                />
              ) : (
                <Segment attached="bottom">
                  <Message
                    fluid
                    header={this.props.t("questions.noQuestions.header")}
                    content={this.props.t("questions.noQuestions.text", {
                      button: this.props.t("questions.addQuestionary"),
                    })}
                  />
                </Segment>
              )}
            </div>
          ) : (
            <div className="mockTable">
              {this.state.error ? (
                <Header
                  style={{ margin: 20, textAlign: "center", display: "block" }}
                  className="connectionError"
                  icon
                >
                  <Icon name="wifi" />
                  {this.props.t("loader.networkError")}
                  <Header.Subheader>
                    <Button onClick={(x) => this.load()}>
                      {this.props.t("loader.tryAgain")}
                    </Button>
                  </Header.Subheader>
                </Header>
              ) : this.state.loading ? (
                <Loader
                  style={{ margin: 20 }}
                  active
                  inline="centered"
                  size="large"
                >
                  {this.props.t("report.loading")}
                </Loader>
              ) : (
                <React.Fragment>
                  <Segment attached="top" className="thead">
                    {this.props.t("questions.reports.header")+ (this.state.archiveStatus ? ': '+this.props.t("questions.archive") : '') }
                  </Segment>
                  {this.state.results.filter((x) => {
                    if(((this.state.archiveStatus && x.archiveStatus) || (!this.state.archiveStatus && !x.archiveStatus)) &&  (x.projectName.toLowerCase().indexOf(this.state.filterValue.toLowerCase()) > -1)){
                        
                        return x;
                      }else{
                        return false
                      }
                    }).length > 0 ? (
                    this.state.results.filter((x) => {
                      if(((this.state.archiveStatus && x.archiveStatus) || (!this.state.archiveStatus && !x.archiveStatus)) &&  (x.projectName.toLowerCase().indexOf(this.state.filterValue.toLowerCase()) > -1)){
                       return x;
                      }else{
                        return false
                      }
                      
                    }).map((x, i, o) => (
                      <Segment
                        className="questionarySegment"
                        attached={i + 1 === o.length ? "bottom" : true}
                        key={x.project + x.date}
                      >
                        <div className="namecell">
                          {x.dateFormatted}
                          {" - "}
                          {x.projectName}
                          
                          {/* {x.answeredByName && false ? (
                            <div className="createdBy">
                            <i>{this.props.t("questions.created")}: {x.answeredByName}</i>
                            </div>
                          ) : null} */}
                        </div>
                        { x.hasImages ? (
                        <div className="buttoncell" style={{marginRight: 5}}>
                          {this.state.downloadAction.isLoading && this.state.downloadAction.project === (x.project +'_'+ x.date) ? (
                            <Button
                            className="ui loading button icon"
                            icon=""
                            data={this.state.downloadAction.project}
                            dataa={x.project}
                          />
                          ): (
                          <Button
                            icon="cloud download"
                            onClick={(e)=> this.downloadImages(e, x)}
                            data={this.state.downloadAction.project}
                            dataa={x.project}
                          />
                          )}
                        </div>
                        ) : null }
                        <div className="buttoncell" style={{marginRight: 5}}>
                          <Button
                            icon="archive"
                            labelPosition="left"
                            content={this.state.archiveStatus ? this.props.t("questions.unarchive") : this.props.t("questions.archive")}
                            onClick={(e)=> this.updateArchiveStatus(e, x, this.state.archiveStatus ? 'UNARCHIVE' : 'ARCHIVE')}
                          />
                        </div>
                        <div className="buttoncell">
                          <ReportModal data={x} project={x.project} date={x.date} />
                        </div>
                      </Segment>
                    ))
                  ) : (
                    <Segment attached="bottom">
                      <Message
                        fluid
                        icon="calendar times outline"
                        header={this.props.t("nothingFoundMessage.header")}
                        content={this.props.t("nothingFoundMessage.message")}
                      />
                    </Segment>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default inject("app")(
  withTranslation()(withRouter(observer(QuestionPage)))
);
