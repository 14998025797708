import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import {
	Button,
	Menu,
	Modal,
	Icon,
	Form,
	Dropdown
} from "semantic-ui-react";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

class StandardExport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			filter: "",
			focused: false,
			start: moment().startOf("day"),
			duration: "2",
			category: null
		};
	}

	open() {
		this.setState({ open: true });
	}

	close() {
		this.setState({ open: false, filter: "" });
	}

	render() {
		return (
			<Modal
				centered={false}
				className="duravermeerModal"
				trigger={
					<Button icon="file excel outline" onClick={(e) => this.open()} />
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				<React.Fragment>
					<Menu>
						<Menu.Item header>{this.props.t("exports.heading")}asdasdasd</Menu.Item>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.close()}>
								<Icon name="close" />
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						<Form as="div">
							<Form.Field>
								<label>{this.props.t("exports.category")}</label>
								<Dropdown
									placeholder={this.props.t("choose.initial")}
									fluid
									value={this.state.category}
									onChange={(e, { value }) => this.setState({category: value})}
									selection
									options={this.props.app.resources.getClassDropdownOptions(false,false).map(x => {
										if (!x.bpo) return false;
										return {
											key: x.id,
											text: x.name,
											value: x.bpo
										};
									}).filter(x => x)}
								/>
							</Form.Field>
							<Form.Field>
								<label>{this.props.t("exports.start")}</label>
								<SingleDatePicker
									date={this.state.start} // momentPropTypes.momentObj or null,
									id="xdpicker_start" // PropTypes.string.isRequired,
									numberOfMonths={3}
									isOutsideRange={x => false}
									onDateChange={(date) =>
										this.setState({
											start: date ? date : moment().startOf("day")
										})
									} // PropTypes.func.isRequired,
									focused={this.state.focused} // PropTypes.bool
  onFocusChange={({ focused }) => this.setState({ focused })} 
									hideKeyboardShortcutsPanel
								/>
							</Form.Field>
							<Form.Field>
								<label>{this.props.t("exports.duration")}</label>
								<Dropdown
									placeholder={this.props.t("choose.initial")}
									fluid
									selection
									onChange={(e, { value }) => this.setState({duration: value})}
									value={this.state.duration}
									options={
										[{
											key: "2",
											text: this.props.t("exports.weeks",{weeks: "2"}),
											value: "2",
										},
										{
											key: "4",
											text: this.props.t("exports.weeks",{weeks: "4"}),
											value: "4",
										},{
											key: "6",
											text: this.props.t("exports.weeks",{weeks: "6"}),
											value: "6",
										},
										{
											key: "8",
											text: this.props.t("exports.weeks",{weeks: "8"}),
											value: "8",
										}]
									}
								/>
							</Form.Field>
						
						
						</Form>
						<form
							className="hiddenForm"
							method="get"
							action={""+process.env.REACT_APP_BPO_URL+"/ressourcen/plan.php"}
							target="_blank"
						>
							<input
								type="hidden"
								name="start"
								value={this.state.start.format("YYYY-MM-DD")}
							/>
							<input type="hidden" name="classid" value={this.state.category ? this.state.category : ""} />
							<input type="hidden" name="niederlassungen" value="" />
							<input type="hidden" name="duration" value={this.state.duration} />
							<Button type="submit" positive fluid>
								{this.props.t("exports.get")}
							</Button>
						</form>
					</Modal.Content>
				</React.Fragment>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(StandardExport)));
